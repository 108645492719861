export const vkSsvg = (
  <svg
    width='27'
    height='15'
    viewBox='0 0 27 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M13.4487 14.8108H14.9879C15.2447 14.7824 15.4879 14.6807 15.6885 14.5179C15.8286 14.3324 15.9014 14.1047 15.8952 13.8724C15.8952 13.8724 15.8608 11.9025 16.8256 11.6119C17.7675 11.3259 18.9735 13.5163 20.2599 14.3582C20.7468 14.7262 21.3514 14.9036 21.9599 14.8567L25.3827 14.8108C25.3827 14.8108 27.1861 14.7039 26.3361 13.3555C25.6512 12.2775 24.789 11.323 23.7861 10.5322C21.6382 8.62323 21.9254 8.93223 24.5097 5.62885C26.0833 3.61766 26.715 2.38979 26.5198 1.86373C26.336 1.36294 25.176 1.49505 25.176 1.49505L21.3167 1.51801C21.1437 1.49896 20.9688 1.52797 20.8113 1.60185C20.6674 1.70243 20.5525 1.83909 20.4781 1.99812C20.0783 2.99363 19.6022 3.95673 19.0539 4.8788C17.331 7.6722 16.6418 7.82036 16.3662 7.64577C15.7115 7.24147 15.8724 6.02169 15.8724 5.15565C15.8724 2.4484 16.2972 1.32044 15.0338 1.02755C14.4429 0.89089 13.8365 0.833355 13.2305 0.856425C12.1559 0.76061 11.0728 0.866979 10.0374 1.17C9.58942 1.3756 9.25632 1.83387 9.46307 1.85914C9.8964 1.89551 10.3012 2.09014 10.6002 2.40588C10.8695 2.92098 11.0003 3.49733 10.9793 4.07824C10.9793 4.07824 11.2089 7.26444 10.4508 7.66071C9.93398 7.93178 9.22195 7.37818 7.68282 4.84322C7.1655 3.96275 6.70503 3.05005 6.30442 2.11071C6.2335 1.95193 6.12744 1.81133 5.99427 1.69951C5.81179 1.58528 5.60898 1.50727 5.39698 1.46977L1.73301 1.49273C1.46123 1.49362 1.19635 1.57869 0.974893 1.73624C0.928795 1.83383 0.903964 1.94009 0.901984 2.048C0.900004 2.15591 0.920909 2.26303 0.963396 2.36224C0.963396 2.36224 3.83499 8.79441 7.08553 12.0323C7.91057 12.892 8.89759 13.5798 9.9897 14.0563C11.0818 14.5328 12.2573 14.7884 13.4487 14.8085V14.8085V14.8108Z'
      fill='#5181B8'
    />
  </svg>
);
