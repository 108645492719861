export const checkSvg = (
  <svg
    width='13'
    height='10'
    viewBox='0 0 13 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12.3317 0.33635C12.7295 0.734106 12.7295 1.379 12.3317 1.77675L4.94486 9.16362L1.2617 5.48046C0.86394 5.0827 0.86394 4.43781 1.2617 4.04005C1.65945 3.6423 2.30434 3.6423 2.7021 4.04005L4.94486 6.28282L10.8913 0.33635C11.2891 -0.0614066 11.934 -0.0614066 12.3317 0.33635Z'
      fill='black'
    />
  </svg>
);
