export const finalResults = [
  {
    id: 1,
    value: 2.0,
    bg: ['result-image-01.jpg', 'mobile/result-image-01-mob.jpg'],
    text: ['1 час', 'сходить в чебуречную'],
  },
  {
    id: 2,
    value: 2.4,
    bg: ['result-image-02.jpg', 'mobile/result-image-02-mob.jpg'],
    text: ['2,4 часа', 'встретить закат на сапборде'],
  },
  {
    id: 3,
    value: 2.8,
    bg: ['result-image-03.jpg', 'mobile/result-image-03-mob.jpg'],
    text: ['2,8 часов', 'посмотреть футбольный матч с друзьями'],
  },
  {
    id: 4,
    value: 3.4,
    bg: ['result-image-04.jpg', 'mobile/result-image-04-mob.jpg'],
    text: ['3,4 часа', 'научиться готовить лучший шашлык'],
  },
  {
    id: 5,
    value: 3.2,
    bg: ['result-image-05.jpg', 'mobile/result-image-05-mob.jpg'],
    text: ['3,2 часа', 'покрасить забор на даче'],
  },
  {
    id: 6,
    value: 3.6,
    bg: ['result-image-06.jpg', 'mobile/result-image-06-mob.jpg'],
    text: ['3,6 часов', 'приготовить всей семьей пельмени'],
  },
  {
    id: 7,
    value: 3.8,
    bg: ['result-image-07.jpg', 'mobile/result-image-07-mob.jpg'],
    text: ['3,8 часов ', 'разобрать гараж или балкон'],
  },
  {
    id: 8,
    value: 4.2,
    bg: ['result-image-08.jpg', 'mobile/result-image-08-mob.jpg'],
    text: ['4,2 часа', 'привести свой автомобиль в идеальный порядок'],
  },
  {
    id: 9,
    value: 4.0,
    bg: ['result-image-09.jpg', 'mobile/result-image-09-mob.jpg'],
    text: ['4 часа', 'разобрать рыболовные снасти'],
  },
  {
    id: 10,
    value: 4.8,
    bg: ['result-image-10.jpg', 'mobile/result-image-10-mob.jpg'],
    text: ['4,8 часов', 'сходить с лучшими друзьями в баню'],
  },
  {
    id: 11,
    value: 5.6,
    bg: ['result-image-11.jpg', 'mobile/result-image-11-mob.jpg'],
    text: ['5,6 часов', 'начать здороваться на испанском'],
  },
  {
    id: 12,
    value: 6.8,
    bg: ['result-image-12.jpg', 'mobile/result-image-12-mob.jpg'],
    text: ['6,8 часов', 'спланировать идеальный отпуск'],
  },
  {
    id: 13,
    value: 6.4,
    bg: ['result-image-13.jpg', 'mobile/result-image-13-mob.jpg'],
    text: ['6,4 часа', 'посмотреть достопримечательности в соседнем городе'],
  },
  {
    id: 14,
    value: 7.2,
    bg: ['result-image-14.jpg', 'mobile/result-image-14-mob.jpg'],
    text: ['7,2 часа', 'научиться варить пиво'],
  },
  {
    id: 15,
    value: 7.6,
    bg: ['result-image-15.jpg', 'mobile/result-image-15-mob.jpg'],
    text: ['7,6 часов', 'помочь другу с переездом'],
  },
  {
    id: 16,
    value: 8.4,
    bg: ['result-image-16.jpg', 'mobile/result-image-16-mob.jpg'],
    text: ['8,4 часа', 'дочитать ту самую книгу, купленную 3 года назад'],
  },
  {
    id: 17,
    value: 6.0,
    bg: ['result-image-17.jpg', 'mobile/result-image-17-mob.jpg'],
    text: ['6 часов', 'сходить на встречу выпускников'],
  },
  {
    id: 18,
    value: 10.2,
    bg: ['result-image-19.jpg', 'mobile/result-image-19-mob.jpg'],
    text: ['10,2 часов', 'съездить на рыбалку'],
  },
  {
    id: 19,
    value: 9.6,
    bg: ['result-image-19.jpg', 'mobile/result-image-19-mob.jpg'],
    text: ['9,6 часов', 'выбраться с семьей на пикник'],
  },
  {
    id: 20,
    value: 10.8,
    bg: ['result-image-20.jpg', 'mobile/result-image-20-mob.jpg'],
    text: ['10,8 часов', 'как следует выспаться'],
  },
  {
    id: 21,
    value: 11.4,
    bg: ['result-image-21.jpg', 'mobile/result-image-21-mob.jpg'],
    text: ['11,4 часа', 'разгадать купленную 5 лет назад судоку'],
  },
  {
    id: 22,
    value: 12.6,
    bg: ['result-image-22.jpg', 'mobile/result-image-22-mob.jpg'],
    text: ['12,6 часов', 'научиться кататься на вейкборде'],
  },
];
