export const warnSvg = (
  <svg
    width='12'
    height='16'
    viewBox='0 0 12 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect y='2' width='12' height='12' rx='6' fill='white' />
    <path
      d='M5.672 5.264H6.616L6.512 9.328H5.776L5.672 5.264ZM6.136 11.048C5.96 11.048 5.81067 10.9893 5.688 10.872C5.57067 10.7493 5.512 10.6053 5.512 10.44C5.512 10.2693 5.57067 10.1253 5.688 10.008C5.81067 9.89067 5.96 9.832 6.136 9.832C6.31733 9.832 6.46667 9.89067 6.584 10.008C6.70667 10.1253 6.768 10.2693 6.768 10.44C6.768 10.6053 6.70667 10.7493 6.584 10.872C6.46667 10.9893 6.31733 11.048 6.136 11.048Z'
      fill='#9E9E9E'
    />
  </svg>
);
