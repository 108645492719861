export const tgSvg = (
  <svg
    width='27'
    height='23'
    viewBox='0 0 27 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M20.4474 22.3095C20.8052 22.5628 21.2663 22.6261 21.6774 22.4706C22.0885 22.3139 22.3907 21.9628 22.4818 21.5373C23.4473 16.9996 25.7895 5.5143 26.6684 1.38662C26.735 1.07552 26.6239 0.752189 26.3795 0.544416C26.135 0.336643 25.7962 0.276644 25.494 0.388864C20.8352 2.11327 6.48771 7.49648 0.623403 9.66643C0.251189 9.8042 0.00897206 10.162 0.021194 10.5542C0.034527 10.9475 0.298966 11.2886 0.680068 11.4031C3.31001 12.1897 6.76215 13.2841 6.76215 13.2841C6.76215 13.2841 8.37545 18.1562 9.21654 20.634C9.32209 20.9451 9.56542 21.1895 9.88653 21.2739C10.2065 21.3573 10.5487 21.2695 10.7876 21.044C12.1387 19.7684 14.2275 17.7962 14.2275 17.7962C14.2275 17.7962 18.1963 20.7062 20.4474 22.3095ZM8.21434 12.6686L10.0799 18.8218L10.4943 14.9252C10.4943 14.9252 17.7019 8.42424 21.8107 4.71877C21.9307 4.60988 21.9474 4.42766 21.8474 4.29989C21.7485 4.17211 21.5663 4.14211 21.4296 4.22878C16.6675 7.26982 8.21434 12.6686 8.21434 12.6686Z'
      fill='#29A3E4'
    />
  </svg>
);
