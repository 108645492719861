export const okSvg = (
  <svg
    width='19'
    height='31'
    viewBox='0 0 19 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.95141 0C5.5552 0 1.99146 3.56378 1.99146 7.95978C1.99146 12.3559 5.5552 15.9199 9.95141 15.9199C14.3476 15.9199 17.9114 12.3559 17.9114 7.95978C17.9114 3.56378 14.3476 0 9.95141 0ZM9.95141 11.2504C8.13417 11.2504 6.66094 9.7771 6.66094 7.9599C6.66094 6.14269 8.13417 4.6695 9.95141 4.6695C11.7686 4.6695 13.2419 6.14269 13.2419 7.9599C13.2419 9.7771 11.7686 11.2504 9.95141 11.2504Z'
      fill='#F7931E'
    />
    <path
      d='M12.2588 22.3319C15.4642 21.6789 17.385 20.1609 17.4866 20.0794C18.4246 19.3272 18.5752 17.9571 17.823 17.019C17.0709 16.081 15.7009 15.9304 14.7627 16.6825C14.7429 16.6985 12.6942 18.2701 9.32335 18.2724C5.95264 18.2701 3.86063 16.6985 3.84081 16.6825C2.90262 15.9304 1.53259 16.081 0.780485 17.019C0.0282662 17.9571 0.17894 19.3272 1.11689 20.0794C1.2199 20.162 3.22001 21.7195 6.51522 22.3572L1.92274 27.1567C1.08813 28.0223 1.11323 29.4004 1.97877 30.235C2.40122 30.6424 2.94571 30.8448 3.48973 30.8448C4.06011 30.8448 4.62992 30.622 5.05708 30.1789L9.32347 25.6341L14.0208 30.2083C14.8719 31.0581 16.2501 31.0567 17.0996 30.2059C17.9491 29.355 17.948 27.9766 17.0972 27.1272L12.2588 22.3319Z'
      fill='#F7931E'
    />
    <path
      d='M9.32361 18.2723C9.31732 18.2723 9.32976 18.2724 9.32361 18.2725C9.31745 18.2724 9.32989 18.2723 9.32361 18.2723Z'
      fill='#F7931E'
    />
  </svg>
);
