const YandexLogo = (
  <svg
    width='228'
    height='33'
    viewBox='0 0 228 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.9859 26.0527H14.3683V6.73904H9.44843C4.5006 6.73904 1.90088 9.26787 1.90088 12.9917C1.90088 15.9651 3.32653 17.7159 5.87035 19.5222L1.45361 26.0527H5.11559L10.0355 18.7441L8.3303 17.6047C6.26171 16.2152 5.25536 15.1314 5.25536 12.7971C5.25536 10.7407 6.70897 9.35124 9.47638 9.35124H10.9859V26.0527ZM76.2026 26.3306C77.7401 26.3306 78.8303 26.0527 79.641 25.4691V22.7736C78.8024 23.3571 77.796 23.7184 76.3983 23.7184C74.0222 23.7184 73.0438 21.8843 73.0438 18.9942C73.0438 15.9651 74.2459 14.4089 76.4263 14.4089C77.7122 14.4089 78.9701 14.8536 79.641 15.2704V12.4637C78.9421 12.0746 77.7122 11.7967 76.0629 11.7967C71.8139 11.7967 69.6055 14.8258 69.6055 19.0776C69.6055 23.7462 71.7579 26.3306 76.2026 26.3306ZM55.4048 25.1079V22.4123C54.3705 23.107 52.6374 23.7184 51.0161 23.7184C48.5841 23.7184 47.6616 22.579 47.5218 20.2447H55.5446V18.494C55.5446 13.6308 53.3922 11.7967 50.0656 11.7967C46.0123 11.7967 44.0835 14.8813 44.0835 19.1053C44.0835 23.9685 46.4875 26.3306 50.7365 26.3306C52.861 26.3306 54.4265 25.7748 55.4048 25.1079ZM24.935 12.0746V17.6047H20.4903V12.0746H17.1637V26.0527H20.4903V20.2169H24.935V26.0527H28.2615V12.0746H24.935ZM43.2728 23.4405H41.7912V12.0746H32.0912V13.2696C32.0912 16.6877 31.8676 21.1062 30.6935 23.4405H29.6592V29.2485H32.7341V26.0527H40.1979V29.2485H43.2728V23.4405ZM66.0274 26.0527H69.8012L64.4619 18.5218L69.1582 12.0746H65.8037L61.1075 18.5218V12.0746H57.7809V26.0527H61.1075V19.1887L66.0274 26.0527ZM50.0097 14.4089C51.659 14.4089 52.1622 15.7706 52.1622 17.5213V17.7992H47.5218C47.6057 15.5761 48.4163 14.4089 50.0097 14.4089ZM38.4647 23.4405H33.7684C34.6909 21.3285 34.9425 17.5213 34.9425 15.1037V14.6868H38.4647V23.4405Z'
      fill='white'
    />
    <mask
      id='mask0_1_1191'
      // style={{'mask-type':'alpha'}}
      maskUnits='userSpaceOnUse'
      x='86'
      y='3'
      width='28'
      height='27'
    >
      <path
        d='M113.126 16.5001C113.126 23.6944 107.259 29.5265 100.023 29.5265C92.7863 29.5265 86.9194 23.6944 86.9194 16.5001C86.9194 9.30584 92.7863 3.47369 100.023 3.47369C107.259 3.47369 113.126 9.30584 113.126 16.5001Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_1_1191)'>
      <path
        id='logo-bg'
        d='M113.127 3.47369H86.9194V29.5265H113.127V3.47369Z'
        fill='#303540'
      />
      <path
        d='M109.476 9.62491H100.023V13.4253H109.476V9.62491Z'
        fill='url(#paint0_linear_1_1191)'
      />
      <path
        d='M109.476 9.62491H100.023V13.4253H109.476V9.62491Z'
        fill='url(#paint1_linear_1_1191)'
        fill-opacity='0.5'
      />
      <path
        d='M100.024 23.3756C96.2098 23.3756 93.1069 20.2911 93.1069 16.4998C93.1069 12.7087 96.2098 9.62463 100.024 9.62463C103.837 9.62463 106.939 12.7091 106.939 16.4998C106.939 20.2906 103.837 23.3756 100.024 23.3756ZM100.024 13.4246C99.2035 13.4255 98.4165 13.7497 97.8364 14.3262C97.2564 14.9027 96.9305 15.6845 96.9296 16.4998C96.9305 17.3153 97.2564 18.0971 97.8364 18.6738C98.4165 19.2504 99.2035 19.5747 100.024 19.5756C100.844 19.5746 101.63 19.2502 102.21 18.6736C102.79 18.097 103.116 17.3152 103.117 16.4998C103.116 15.6846 102.79 14.9029 102.209 14.3264C101.629 13.7499 100.843 13.4255 100.024 13.4246Z'
        fill='#42FF98'
      />
      <path
        d='M96.9294 16.4998L93.8379 19.5733C94.4127 20.715 95.2959 21.6752 96.3878 22.3468C97.4806 23.0182 98.7395 23.3744 100.024 23.3756V19.5756C99.2033 19.5748 98.4162 19.2504 97.8362 18.6738C97.2561 18.0971 96.9303 17.3153 96.9294 16.4998Z'
        fill='url(#paint2_linear_1_1191)'
      />
      <path
        d='M96.9294 16.4998L93.8379 19.5733C94.4127 20.715 95.2959 21.6752 96.3878 22.3468C97.4806 23.0182 98.7395 23.3744 100.024 23.3756V19.5756C99.2033 19.5748 98.4162 19.2504 97.8362 18.6738C97.2561 18.0971 96.9303 17.3153 96.9294 16.4998Z'
        fill='url(#paint3_linear_1_1191)'
        fill-opacity='0.5'
      />
      <path
        d='M93.1069 29.5264C94.2897 29.5264 95.6551 29.5264 96.9296 29.5264V16.4998H93.1069V29.5264Z'
        fill='url(#paint4_linear_1_1191)'
      />
    </g>
    <path
      d='M125.01 26.3306C128.896 26.3306 131.635 24.4131 131.635 20.5504C131.635 17.9382 130.238 16.4376 127.945 15.9373C129.762 15.3538 131.048 14.0199 131.048 11.5188C131.048 8.29526 128.952 6.46115 125.234 6.46115C122.914 6.46115 121.069 7.10031 120.09 7.73947V10.435C121.292 9.76808 123.109 9.07334 124.898 9.07334C126.66 9.07334 127.694 9.93482 127.694 11.9357C127.694 13.7976 126.632 14.8258 124.703 14.8258H122.355V17.438H124.843C127.135 17.438 128.197 18.3272 128.197 20.4392C128.197 22.6624 126.939 23.7184 124.563 23.7184C122.578 23.7184 120.929 23.0515 119.643 22.2178V24.9133C120.621 25.5803 122.438 26.3306 125.01 26.3306Z'
      fill='white'
    />
    <path
      d='M144.444 16.5209C144.444 13.1306 142.711 11.8523 139.189 11.8523C136.981 11.8523 135.248 12.547 134.241 13.1306V15.8818C135.136 15.2148 137.093 14.4923 138.798 14.4923C140.391 14.4923 141.118 15.0481 141.118 16.5487V17.3268H140.587C135.499 17.3268 133.235 18.9942 133.235 21.8287C133.235 24.6632 134.968 26.2472 137.54 26.2472C139.497 26.2472 140.335 25.6081 140.978 24.9411H141.118C141.146 25.3024 141.258 25.7748 141.369 26.0527H144.612C144.5 24.9133 144.444 23.774 144.444 22.6346V16.5209ZM141.118 22.718C140.699 23.3293 139.916 23.8296 138.742 23.8296C137.344 23.8296 136.645 22.9959 136.645 21.7453C136.645 20.1058 137.791 19.5222 140.643 19.5222H141.118V22.718Z'
      fill='white'
    />
    <path
      d='M147.119 12.0746V26.0527H150.445V14.6868H154.75V26.0527H158.077V12.0746H147.119Z'
      fill='white'
    />
    <path
      d='M163.952 12.0746H160.877V30.0822H164.203V24.4131C165.042 25.6637 166.272 26.3306 167.698 26.3306C170.94 26.3306 173.177 23.7462 173.177 19.0498C173.177 14.3811 170.996 11.7967 167.865 11.7967C166.3 11.7967 165.014 12.5192 164.12 13.8809L163.952 12.0746ZM166.859 23.7184C165.098 23.7184 164.203 22.3011 164.203 19.0776C164.203 15.8262 165.154 14.4089 167.027 14.4089C168.844 14.4089 169.738 15.8262 169.738 19.0498C169.738 22.3011 168.788 23.7184 166.859 23.7184Z'
      fill='white'
    />
    <path
      d='M185.775 16.5209C185.775 13.1306 184.041 11.8523 180.519 11.8523C178.311 11.8523 176.578 12.547 175.571 13.1306V15.8818C176.466 15.2148 178.423 14.4923 180.128 14.4923C181.721 14.4923 182.448 15.0481 182.448 16.5487V17.3268H181.917C176.829 17.3268 174.565 18.9942 174.565 21.8287C174.565 24.6632 176.298 26.2472 178.87 26.2472C180.827 26.2472 181.665 25.6081 182.308 24.9411H182.448C182.476 25.3024 182.588 25.7748 182.7 26.0527H185.942C185.83 24.9133 185.775 23.774 185.775 22.6346V16.5209ZM182.448 22.718C182.029 23.3293 181.246 23.8296 180.072 23.8296C178.674 23.8296 177.975 22.9959 177.975 21.7453C177.975 20.1058 179.121 19.5222 181.973 19.5222H182.448V22.718Z'
      fill='white'
    />
    <path
      d='M193.984 26.0527C197.283 26.0527 199.239 24.6632 199.239 21.9955C199.239 20.1614 198.121 19.1054 196.248 18.7719C197.758 18.355 198.708 17.299 198.708 15.6317C198.708 13.2418 197.115 12.0746 194.04 12.0746H188.449V26.0527H193.984ZM193.565 14.5757C194.767 14.5757 195.438 15.0759 195.438 16.1319C195.438 17.1045 194.711 17.6881 193.453 17.6881H191.776V14.5757H193.565ZM193.621 20.1058C195.046 20.1058 195.801 20.606 195.801 21.7731C195.801 23.0515 194.963 23.5517 193.621 23.5517H191.776V20.1058H193.621Z'
      fill='white'
    />
    <path
      d='M209.717 26.0527H213.491L208.152 18.5218L212.848 12.0746H209.494L204.797 18.5218V12.0746H201.471V26.0527H204.797V19.1887L209.717 26.0527Z'
      fill='white'
    />
    <path
      d='M214.766 12.0746V26.0527H217.645L222.788 17.4102V26.0527H226.031V12.0746H223.152L218.008 20.7171V12.0746H214.766Z'
      fill='white'
    />
    <defs>
      <linearGradient
        id='paint0_linear_1_1191'
        x1='104.749'
        y1='9.62491'
        x2='104.749'
        y2='13.4253'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#42FF98' />
        <stop offset='1' stop-color='#00C7E2' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1_1191'
        x1='102.682'
        y1='14.1582'
        x2='104.238'
        y2='9.6261'
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop offset='1' stop-opacity='0' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1_1191'
        x1='100.002'
        y1='21.3499'
        x2='94.8043'
        y2='20.1881'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#42FF98' />
        <stop offset='1' stop-color='#00C7E2' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_1_1191'
        x1='95.24'
        y1='23.3718'
        x2='98.9848'
        y2='23.5598'
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop offset='1' stop-opacity='0' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_1_1191'
        x1='95.0183'
        y1='19.1054'
        x2='95.0183'
        y2='29.1602'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#42FF98' />
        <stop offset='1' stop-color='#00C7E2' />
      </linearGradient>
    </defs>
  </svg>
);

export default YandexLogo;
