const LogoYandexMobile = (
  <svg
    width='164'
    height='33'
    viewBox='0 0 164 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_1_1465)'>
      <mask
        id='mask0_1_1465'
        // style='mask-type:alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='3'
        width='26'
        height='27'
      >
        <ellipse
          cx='12.7132'
          cy='16.6809'
          rx='12.7132'
          ry='12.8577'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1_1465)'>
        <rect
          width='25.4265'
          height='25.7153'
          transform='translate(0 3.82324)'
          fill='#303540'
        />
        <path
          d='M14.5878 11.024H13.3012C11.0955 11.024 9.99265 12.1394 9.99265 13.8124C9.99265 15.6714 10.7279 16.6008 12.3821 17.7162L13.6688 18.6457L9.99265 24.4084H7.05176L10.5441 19.2034C8.52221 17.7162 7.41937 16.4149 7.41937 13.9983C7.41937 11.024 9.44124 8.97919 13.3012 8.97919H17.1611V24.4084H14.5878V11.024Z'
          fill='white'
        />
      </g>
    </g>
    <g clip-path='url(#clip1_1_1465)'>
      <mask
        id='mask1_1_1465'
        // style='mask-type:alpha'
        maskUnits='userSpaceOnUse'
        x='27'
        y='3'
        width='26'
        height='27'
      >
        <path
          d='M52.5481 16.6804C52.5481 23.7815 46.8562 29.538 39.8348 29.538C32.8135 29.538 27.1216 23.7815 27.1216 16.6804C27.1216 9.57927 32.8135 3.82269 39.8348 3.82269C46.8562 3.82269 52.5481 9.57927 52.5481 16.6804Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_1_1465)'>
        <rect
          x='27.1216'
          y='3.82236'
          width='25.4265'
          height='25.7153'
          fill='#303540'
        />
        <path
          d='M49.0055 9.89386H39.8342V13.645H49.0055V9.89386Z'
          fill='url(#paint0_linear_1_1465)'
        />
        <path
          d='M49.0055 9.89386H39.8342V13.645H49.0055V9.89386Z'
          fill='url(#paint1_linear_1_1465)'
          fill-opacity='0.5'
        />
        <path
          d='M39.8351 23.4664C36.1351 23.4664 33.1248 20.4219 33.1248 16.6798C33.1248 12.9376 36.1351 9.89359 39.8351 9.89359C43.5352 9.89359 46.545 12.9381 46.545 16.6798C46.545 20.4214 43.5348 23.4664 39.8351 23.4664ZM39.8351 13.6443C39.0393 13.6452 38.2764 13.9652 37.7136 14.5343C37.1509 15.1033 36.8343 15.8749 36.8334 16.6798C36.8342 17.4847 37.1507 18.2564 37.7135 18.8255C38.2762 19.3947 39.0393 19.7148 39.8351 19.7156C40.6309 19.7147 41.3938 19.3945 41.9565 18.8254C42.5192 18.2562 42.8356 17.4846 42.8365 16.6798C42.8355 15.875 42.519 15.1035 41.9563 14.5344C41.3937 13.9654 40.6308 13.6453 39.8351 13.6443Z'
          fill='#42FF98'
        />
        <path
          d='M36.8335 16.6798L33.834 19.7134C34.3918 20.8403 35.2484 21.7881 36.3082 22.4509C37.3681 23.1137 38.5892 23.4653 39.8353 23.4664V19.7157C39.0394 19.7148 38.2764 19.3947 37.7136 18.8255C37.1509 18.2564 36.8343 17.4847 36.8335 16.6798Z'
          fill='url(#paint2_linear_1_1465)'
        />
        <path
          d='M36.8335 16.6798L33.834 19.7134C34.3918 20.8403 35.2484 21.7881 36.3082 22.4509C37.3681 23.1137 38.5892 23.4653 39.8353 23.4664V19.7157C39.0394 19.7148 38.2764 19.3947 37.7136 18.8255C37.1509 18.2564 36.8343 17.4847 36.8335 16.6798Z'
          fill='url(#paint3_linear_1_1465)'
          fill-opacity='0.5'
        />
        <path
          d='M33.1248 29.5376C34.2729 29.5376 35.5972 29.5376 36.8338 29.5376V16.6798H33.1248V29.5376Z'
          fill='url(#paint4_linear_1_1465)'
        />
      </g>
    </g>
    <path
      d='M64.0782 26.3834C67.848 26.3834 70.5059 24.4908 70.5059 20.6781C70.5059 18.0997 69.1499 16.6185 66.9259 16.1248C68.6888 15.5488 69.9364 14.2322 69.9364 11.7635C69.9364 8.58168 67.9023 6.77133 64.2951 6.77133C62.0441 6.77133 60.254 7.40221 59.3048 8.03309V10.6937C60.471 10.0354 62.2339 9.3497 63.9697 9.3497C65.6783 9.3497 66.6818 10.2 66.6818 12.1749C66.6818 14.0127 65.6512 15.0276 63.7798 15.0276H61.5016V17.606H63.9154C66.1394 17.606 67.17 18.4837 67.17 20.5684C67.17 22.7627 65.9495 23.805 63.6442 23.805C61.7186 23.805 60.1184 23.1467 58.8708 22.3238V24.9845C59.8201 25.6428 61.583 26.3834 64.0782 26.3834Z'
      fill='white'
    />
    <path
      d='M82.9332 16.7008C82.9332 13.3544 81.2517 12.0927 77.8344 12.0927C75.6918 12.0927 74.0103 12.7784 73.0339 13.3544V16.0699C73.9018 15.4116 75.8003 14.6985 77.4547 14.6985C79.0006 14.6985 79.7058 15.247 79.7058 16.7282V17.4963H79.1905C74.2544 17.4963 72.0576 19.142 72.0576 21.9398C72.0576 24.7376 73.7391 26.3011 76.2343 26.3011C78.1328 26.3011 78.9464 25.6702 79.5702 25.0119H79.7058C79.7329 25.3685 79.8414 25.8348 79.9499 26.1091H83.096C82.9875 24.9845 82.9332 23.8599 82.9332 22.7353V16.7008ZM79.7058 22.8176C79.299 23.421 78.5396 23.9148 77.4005 23.9148C76.0444 23.9148 75.3664 23.0919 75.3664 21.8575C75.3664 20.2392 76.4783 19.6632 79.2447 19.6632H79.7058V22.8176Z'
      fill='white'
    />
    <path
      d='M85.5282 12.3121V26.1091H88.7556V14.8905H92.9323V26.1091H96.1598V12.3121H85.5282Z'
      fill='white'
    />
    <path
      d='M101.86 12.3121H98.877V30.0864H102.104V24.4908C102.918 25.7251 104.111 26.3834 105.495 26.3834C108.641 26.3834 110.81 23.8325 110.81 19.1969C110.81 14.5887 108.695 12.0378 105.657 12.0378C104.139 12.0378 102.891 12.751 102.023 14.095L101.86 12.3121ZM104.681 23.805C102.972 23.805 102.104 22.4061 102.104 19.2243C102.104 16.0151 103.027 14.6162 104.844 14.6162C106.607 14.6162 107.474 16.0151 107.474 19.1969C107.474 22.4061 106.552 23.805 104.681 23.805Z'
      fill='white'
    />
    <path
      d='M123.033 16.7008C123.033 13.3544 121.351 12.0927 117.934 12.0927C115.791 12.0927 114.11 12.7784 113.133 13.3544V16.0699C114.001 15.4116 115.9 14.6985 117.554 14.6985C119.1 14.6985 119.805 15.247 119.805 16.7282V17.4963H119.29C114.354 17.4963 112.157 19.142 112.157 21.9398C112.157 24.7376 113.838 26.3011 116.334 26.3011C118.232 26.3011 119.046 25.6702 119.67 25.0119H119.805C119.832 25.3685 119.941 25.8348 120.049 26.1091H123.195C123.087 24.9845 123.033 23.8599 123.033 22.7353V16.7008ZM119.805 22.8176C119.398 23.421 118.639 23.9148 117.5 23.9148C116.144 23.9148 115.466 23.0919 115.466 21.8575C115.466 20.2392 116.578 19.6632 119.344 19.6632H119.805V22.8176Z'
      fill='white'
    />
    <path
      d='M130.998 26.1091C134.198 26.1091 136.096 24.7376 136.096 22.1044C136.096 20.2941 135.012 19.2517 133.194 18.9226C134.659 18.5112 135.581 17.4688 135.581 15.8231C135.581 13.4641 134.035 12.3121 131.052 12.3121H125.628V26.1091H130.998ZM130.591 14.7807C131.757 14.7807 132.408 15.2745 132.408 16.3168C132.408 17.2768 131.703 17.8528 130.482 17.8528H128.855V14.7807H130.591ZM130.645 20.2392C132.028 20.2392 132.761 20.7329 132.761 21.885C132.761 23.1467 131.947 23.6405 130.645 23.6405H128.855V20.2392H130.645Z'
      fill='white'
    />
    <path
      d='M146.262 26.1091H149.923L144.743 18.6757L149.3 12.3121H146.045L141.489 18.6757V12.3121H138.261V26.1091H141.489V19.334L146.262 26.1091Z'
      fill='white'
    />
    <path
      d='M151.16 12.3121V26.1091H153.953L158.944 17.5785V26.1091H162.09V12.3121H159.296L154.306 20.8427V12.3121H151.16Z'
      fill='white'
    />
    <defs>
      <linearGradient
        id='paint0_linear_1_1465'
        x1='44.4199'
        y1='9.89386'
        x2='44.4199'
        y2='13.645'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#42FF98' />
        <stop offset='1' stop-color='#00C7E2' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1_1465'
        x1='42.4149'
        y1='14.3685'
        x2='43.9708'
        y2='9.91151'
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop offset='1' stop-opacity='0' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1_1465'
        x1='39.8142'
        y1='21.467'
        x2='34.7633'
        y2='20.3572'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#42FF98' />
        <stop offset='1' stop-color='#00C7E2' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_1_1465'
        x1='35.1947'
        y1='23.4628'
        x2='38.828'
        y2='23.642'
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop offset='1' stop-opacity='0' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_1_1465'
        x1='34.9793'
        y1='19.2515'
        x2='34.9793'
        y2='29.1762'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#42FF98' />
        <stop offset='1' stop-color='#00C7E2' />
      </linearGradient>
      <clipPath id='clip0_1_1465'>
        <rect
          width='25.4265'
          height='25.7153'
          fill='white'
          transform='translate(0 3.82324)'
        />
      </clipPath>
      <clipPath id='clip1_1_1465'>
        <rect
          width='25.4265'
          height='25.7153'
          fill='white'
          transform='translate(27.1216 3.82236)'
        />
      </clipPath>
    </defs>
  </svg>
);

export default LogoYandexMobile;
